import { zodResolver } from "@hookform/resolvers/zod";
import { Head } from "components/info/Head";
import { Container } from "foundations/Container";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useBulkSearchCities } from "usecase/city/city";
import {
  useGetCondition,
  useUpdateCondition,
} from "usecase/customerCondition/customerCondition";
import { useBulkSearchStations } from "usecase/station/station";
import { logError } from "util/error/logError";
import { z } from "zod";

import { ErrorPage } from "../Error/Error";
import styles from "./CustomerCondition.module.scss";
import { CheckConditionForm } from "./modules/CheckConditionForm";
import { Completed } from "./modules/Completed";
import { ConditionForm } from "./modules/ConditionForm";
import {
  CONDITION_FORM_DEFAULT_VALUES,
  convertRentMax,
  convertRentMin,
  convertSquareMax,
  convertSquareMin,
  MAX_CHOMEI_COUNT,
  MAX_CITY_COUNT,
  RENT_MAX_UPPER_VALUE,
  RENT_MIN_LOWER_VALUE,
  SQUARE_MAX_UPPER_VALUE,
  SQUARE_MIN_LOWER_VALUE,
} from "./modules/constant";

const validationSchema = z
  .object({
    customerId: z.string(),
    moveInTiming: z.string().refine((value) => value.trim() !== "", {
      message:
        "こちらの項目は入力が必須です。ご希望の入居時期を入力してください。",
    }),
    rentMax: z.number(),
    rentMin: z.number(),
    // フォームの制御のために値を保持しているが、リクエストには送信しない
    chomeiIdsList: z.array(z.string()).max(MAX_CHOMEI_COUNT, {
      message: `町村は最大${MAX_CHOMEI_COUNT}件までしか選択できません。`,
    }),
    cityIdsList: z.array(z.string()).max(MAX_CITY_COUNT, {
      message: `市区郡は最大${MAX_CITY_COUNT}件までしか選択できません。`,
    }),
    citiesList: z.array(
      z.object({
        id: z.string(),
        chomeiIdsList: z.array(z.string()),
      })
    ),
    stationIdAndLineIdsList: z.array(z.string()),
    squareMax: z.number(),
    squareMin: z.number(),
    layoutIdsList: z.array(z.string()),
    walkDuringMax: z
      .string()
      .regex(new RegExp(/^[0-9]+$/), {
        message: "半角数字の整数のみ入力可能です。",
      })
      .or(z.literal("")),
    oldMax: z
      .string()
      .regex(new RegExp(/^[0-9]+$/), {
        message: "半角数字の整数のみ入力可能です。",
      })
      .or(z.literal("")),
    customerBukkenOptionIdsList: z.array(z.string()),
    other: z.string(),
    phoneNumber: z
      .string()
      .regex(new RegExp(/^0(\d{1,4}-\d{1,4}-\d{3,4}|\d{9,10})$/), {
        message: "半角数字で入力してください。",
      })
      .or(z.literal("")),
  })
  .refine(({ rentMin, rentMax }) => rentMin <= rentMax, {
    message: "最小賃料より最大賃料を上回ることはできません。",
    path: ["rentMin"],
  })
  .refine(({ squareMin, squareMax }) => squareMin <= squareMax, {
    message: "最小面積より最大面積を上回ることはできません。",
    path: ["squareMin"],
  })
  .refine(
    ({ stationIdAndLineIdsList, chomeiIdsList, cityIdsList }) => {
      return (
        stationIdAndLineIdsList.length > 0 ||
        chomeiIdsList.length > 0 ||
        cityIdsList.length > 0
      );
    },
    {
      message:
        "こちらの項目は入力が必須です。ご希望のエリアを入力してください。",
      path: ["stationIdAndLineIdsList"],
    }
  );

export type ConditionFormSchema = z.infer<typeof validationSchema>;

export const CustomerConditionForm = () => {
  const { data } = useGetCondition();
  const customer = data.customer;
  const condition = data.condition;
  const layoutOptions = data.layoutsList;
  const prefecturesList = data.prefecturesList;
  const bukkenShubetsuOptions = (
    condition?.customerBukkenOptionCategoriesList || []
  ).find((category) => category.id === "shubetsu");
  const otherOptions = (
    condition?.customerBukkenOptionCategoriesList || []
  ).filter((category) => category.id !== "shubetsu");
  const prefectureIdsList = prefecturesList.map((prefecture) => ({
    prefectureId: prefecture.id,
  }));

  // 市区町村の一括取得
  const bulkSearchCities = useBulkSearchCities(prefectureIdsList);
  const citiesByPrefecture = bulkSearchCities.map(({ data }) => data);
  const allCities = citiesByPrefecture
    .flatMap((area) => area.citiesListInThisPrefecture)
    .flat();
  const allChomeis = allCities.flatMap((city) => city.chomeisList).flat();
  // 路線・駅の一括取得
  const bulkSearchStations = useBulkSearchStations(prefectureIdsList);
  const stationsByPrefecture = bulkSearchStations.map(({ data }) => data);
  const allStations = stationsByPrefecture
    .flatMap((stations) => stations.stationsListInThisPrefecture)
    .flat();

  const updateCondition = useUpdateCondition();

  const [displayedPageName, setDisplayedPageName] = useState<
    "form" | "check" | "completed" | "error"
  >("form");

  const conditionValues: ConditionFormSchema = {
    customerId: customer?.id || "",
    moveInTiming: condition?.moveInTiming || "",
    rentMin: condition?.rentMin?.hasValue
      ? convertRentMin(condition.rentMin.value)
      : RENT_MIN_LOWER_VALUE,
    rentMax: condition?.rentMax?.hasValue
      ? convertRentMax(condition.rentMax.value)
      : RENT_MAX_UPPER_VALUE,
    stationIdAndLineIdsList:
      condition?.stationAndLinesList
        .map((station) => station.station?.id || "")
        .filter((stationId) => !!stationId) || [],
    // フォームの制御のために値を保持しているが、リクエストには送信しない
    chomeiIdsList:
      condition?.citiesList.flatMap((city) => {
        if (city.chomeisList.length === 0) {
          return [];
        }
        return city.chomeisList.map((chomei) => chomei.chomeiId);
      }) || [],
    citiesList:
      condition?.citiesList.map((city) => ({
        id: city.cityId,
        chomeiIdsList: city.chomeisList.map((chomei) => chomei.chomeiId),
      })) || [],
    cityIdsList: condition?.citiesList.map((city) => city.cityId) || [],
    squareMin: condition?.squareMin?.hasValue
      ? convertSquareMin(condition.squareMin.value)
      : SQUARE_MIN_LOWER_VALUE,
    squareMax: condition?.squareMax?.hasValue
      ? convertSquareMax(condition.squareMax.value)
      : SQUARE_MAX_UPPER_VALUE,
    layoutIdsList: condition?.layoutsList.map((layout) => layout.id) || [],
    walkDuringMax: condition?.walkDuringMax?.hasValue
      ? String(condition.walkDuringMax.value || "")
      : "",
    oldMax: condition?.oldMax?.hasValue
      ? String(condition.oldMax.value || "")
      : "",
    customerBukkenOptionIdsList:
      condition?.customerBukkenOptionCategoriesList.reduce<string[]>(
        (acc, cur) => {
          cur.optionsList.forEach((option) => {
            if (option.selected) {
              acc.push(option.id);
            }
          });
          return acc;
        },
        []
      ) || [],
    other: condition?.other || "",
    phoneNumber: customer?.phoneNumber || "",
  };

  const methods = useForm<ConditionFormSchema>({
    mode: "onChange",
    resolver: zodResolver(validationSchema),
    defaultValues: {
      ...CONDITION_FORM_DEFAULT_VALUES,
      ...conditionValues,
    },
  });

  const onClickDisplayedCheck = () => setDisplayedPageName("check");
  const onClickDisplayedForm = () => setDisplayedPageName("form");
  const onClickUpdateCondition = async () => {
    try {
      const condition = methods.getValues();
      await updateCondition.mutateAsync(
        {
          customerId: condition.customerId,
          phoneNumber: condition.phoneNumber,
          moveInTiming: condition.moveInTiming,
          // @ts-expect-error noUncheckedIndexedAccess
          stationIdAndLineIdsList: condition.stationIdAndLineIdsList.map(
            (stationId) => {
              const [lineId] = stationId.split("_"); // {lineId}_{stationId}
              return { lineId, stationId };
            }
          ),
          rentMax: condition.rentMax,
          rentMin: condition.rentMin,
          squareMax: condition.squareMax,
          squareMin: condition.squareMin,
          layoutIdsList: condition.layoutIdsList,
          citiesList: condition.citiesList,
          cityIdsList: condition.cityIdsList,
          walkDuringMax: Number(condition.walkDuringMax || 0),
          oldMax: Number(condition.oldMax || 0),
          other: condition.other,
          customerBukkenOptionIdsList: condition.customerBukkenOptionIdsList,
        },
        {
          onSuccess: () => {
            setDisplayedPageName("completed");
          },
        }
      );
    } catch (e) {
      setDisplayedPageName("error");
      logError(e);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [displayedPageName]);

  return (
    <Container>
      {displayedPageName === "error" ? (
        <ErrorPage error="unexpected-run-time-error" />
      ) : displayedPageName === "completed" ? (
        <>
          <Head title="ご希望条件フォーム送信完了ページ" />
          <Completed />
        </>
      ) : (
        <>
          <Head title="ご希望条件フォーム" />
          <div className={styles.container}>
            <h1 className={styles.title}>ご希望条件について</h1>
            <FormProvider {...methods}>
              {displayedPageName === "form" ? (
                <ConditionForm
                  allChomeis={allChomeis}
                  allCities={allCities}
                  allStations={allStations}
                  bukkenShubetsuOptions={bukkenShubetsuOptions}
                  citiesByPrefecture={citiesByPrefecture}
                  layoutOptions={layoutOptions}
                  otherOptions={otherOptions}
                  prefecturesList={prefecturesList}
                  stationsByPrefecture={stationsByPrefecture}
                  onClickDisplayedCheck={onClickDisplayedCheck}
                />
              ) : (
                <CheckConditionForm
                  allCities={allCities}
                  allStations={allStations}
                  bukkenShubetsuOptions={bukkenShubetsuOptions}
                  isSubmitting={methods.formState.isSubmitting}
                  layoutOptions={layoutOptions}
                  otherOptions={otherOptions}
                  onClickDisplayedForm={onClickDisplayedForm}
                  onClickUpdateCondition={onClickUpdateCondition}
                />
              )}
            </FormProvider>
          </div>
        </>
      )}
    </Container>
  );
};
