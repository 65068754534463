import { Head } from "components/info/Head";
import { PageLoading } from "components/loading/PageLoading";
import { Container } from "foundations/Container";
import { ErrorPage } from "pages/Error/Error";
import { useState } from "react";
import {
  useDoRejectAutoButsudashi,
  useRejectAutoButsudashiPageView,
} from "usecase/rejectAutoButsudashi/rejectAutoButsudashi";
import { useDoRestartDelivery } from "usecase/restartDelivery/restartDelivery";
import { checkErrorType, isGrpcError } from "util/error/error";
import { logError } from "util/error/logError";

import { CompletedRejectAutoButsudashiView } from "./modules/CompletedRejectAutoButsudashiView";
import { CompletedRestartDeliveryView } from "./modules/CompletedRestartDeliveryView";
import type { RejectAutoButsudashiFormValues } from "./modules/RejectAutoButsudashiForm";
import { RejectAutoButsudashiForm } from "./modules/RejectAutoButsudashiForm";
import styles from "./RejectAutoButsudashi.module.scss";

type ViewType =
  | "reject-auto-butsudashi-form"
  | "completed-restart-delivery"
  | "completed-reject-auto-butsudashi";

const VIEW_TYPE_MAP: Record<string, ViewType> = {
  REJECT_AUTO_BUTSUDASHI_FORM: "reject-auto-butsudashi-form",
  COMPLETED_REJECT_AUTO_BUTSUDASHI: "completed-reject-auto-butsudashi",
  COMPLETED_RESTART_DELIVERY: "completed-restart-delivery",
};

export const RejectAutoButsudashiPage = () => {
  const { data, error, isError, isPending } = useRejectAutoButsudashiPageView();
  const doRejectAutoButsudashi = useDoRejectAutoButsudashi();
  const doRestartDelivery = useDoRestartDelivery();
  const [shouldDisplayedView, setShouldDisplayedView] =
    useState<ViewType | null>(null);

  /**
   * 配信停止を実行する
   */
  const handleSubmitRejectDelivery = async (
    values: RejectAutoButsudashiFormValues
  ) => {
    try {
      await doRejectAutoButsudashi.mutateAsync({
        reasonId: values.reasonID,
        opinion: values.opinion,
      });
      // 配信停止完了画面に遷移
      // @ts-expect-error noUncheckedIndexedAccess
      setShouldDisplayedView(VIEW_TYPE_MAP.COMPLETED_REJECT_AUTO_BUTSUDASHI);
    } catch (e) {
      logError(e);
    }
  };

  /**
   * 配信再開を実行する
   */
  const handleClickDisplayedRestartDelivery = async () => {
    try {
      await doRestartDelivery.mutateAsync({});
      // 配信停止完了画面に遷移
      // @ts-expect-error noUncheckedIndexedAccess
      setShouldDisplayedView(VIEW_TYPE_MAP.COMPLETED_RESTART_DELIVERY);
    } catch (e) {
      logError(e);
    }
  };

  /**
   * 配信再開完了画面から再度配信停止画面に遷移する
   */
  const handleClickDisplayedRejectAutoButsudashiForm = () => {
    // @ts-expect-error noUncheckedIndexedAccess
    setShouldDisplayedView(VIEW_TYPE_MAP.REJECT_AUTO_BUTSUDASHI_FORM);
  };

  if (isPending || !data) {
    return <PageLoading fullscreen />;
  }

  if (isError && error) {
    return (
      <ErrorPage
        error={isGrpcError(error) ? checkErrorType(error.code) : "other"}
      />
    );
  }

  if (doRejectAutoButsudashi.isError && doRejectAutoButsudashi.error) {
    return (
      <ErrorPage
        error={
          isGrpcError(doRejectAutoButsudashi.error)
            ? checkErrorType(doRejectAutoButsudashi.error.code)
            : "other"
        }
      />
    );
  }

  if (doRestartDelivery.isError && doRestartDelivery.error) {
    return (
      <ErrorPage
        error={
          isGrpcError(doRestartDelivery.error)
            ? checkErrorType(doRestartDelivery.error.code)
            : "other"
        }
      />
    );
  }

  // 初回表示以降で表示するViewを切り替えた場合
  if (shouldDisplayedView !== null) {
    return (
      <Container>
        <div className={styles.container}>
          {shouldDisplayedView ===
          VIEW_TYPE_MAP.COMPLETED_REJECT_AUTO_BUTSUDASHI ? (
            <>
              <Head title="物件配信メール停止完了ページ" />
              <CompletedRejectAutoButsudashiView
                onClickDisplayRestartDelivery={
                  handleClickDisplayedRestartDelivery
                }
              />
            </>
          ) : shouldDisplayedView ===
            VIEW_TYPE_MAP.COMPLETED_RESTART_DELIVERY ? (
            <>
              <Head title="物件配信メール再開完了ページ" />
              <CompletedRestartDeliveryView
                onClickDisplayedRejectAutoButsudashiForm={
                  handleClickDisplayedRejectAutoButsudashiForm
                }
              />
            </>
          ) : shouldDisplayedView ===
            VIEW_TYPE_MAP.REJECT_AUTO_BUTSUDASHI_FORM ? (
            <>
              <Head title="物件配信メール停止ページ" />
              <RejectAutoButsudashiForm
                reasons={data.reasonsList}
                onSubmit={handleSubmitRejectDelivery}
              />
            </>
          ) : null}
        </div>
      </Container>
    );
  }

  // 初回表示の場合

  const renderHeadTitle = data.isRejectAutoButsudashi
    ? "物件配信メール停止完了ページ"
    : "物件配信メール停止ページ";
  const renderView = data.isRejectAutoButsudashi ? (
    <CompletedRejectAutoButsudashiView
      onClickDisplayRestartDelivery={handleClickDisplayedRestartDelivery}
    />
  ) : (
    <RejectAutoButsudashiForm
      reasons={data.reasonsList}
      onSubmit={handleSubmitRejectDelivery}
    />
  );

  return (
    <>
      <Head title={renderHeadTitle} />
      <Container>
        <div className={styles.container}>{renderView}</div>
      </Container>
    </>
  );
};
