import * as Sentry from "@sentry/react";
import type {
  GetStoreResponse,
  GetTokenResponse,
} from "apis/proto/canary_cloud/customer/v1/api_pb";
import {
  EmptyRequest,
  GetTokenRequest,
} from "apis/proto/canary_cloud/customer/v1/api_pb";
import { ApiV1Client } from "apis/proto/canary_cloud/customer/v1/ApiServiceClientPb";
import { PageLoading } from "components/loading/PageLoading";
import { Header } from "foundations/Header";
import { ErrorPage } from "pages/Error/Error";
import type { ReactNode } from "react";
import { createContext, useEffect, useState } from "react";
import { checkErrorType, isGrpcError } from "util/error/error";
import { logError } from "util/error/logError";
import type {
  APIBaseResponseType,
  SuccessStateAPIResponse,
} from "util/utilTypes";

type SuccessAuthContextData = {
  authCode: string;
  customerId: string;
  isEnableStoreVisitAppointment: boolean;
  name: string;
  organizationID: string;
  token: string;
};

type AuthContext = APIBaseResponseType<SuccessAuthContextData>;

export const checkSuccessAuthContext = (
  context: AuthContext
): context is SuccessStateAPIResponse<SuccessAuthContextData> => {
  return context.state === "success";
};

export const AuthContext = createContext<AuthContext>({
  state: "initial",
  data: null,
});

type Props = {
  children: ReactNode;
};

const getToken = async (
  authCode: string,
  organizationID: string
): Promise<GetTokenResponse.AsObject> => {
  const client = new ApiV1Client(
    process.env["REACT_APP_API_BASE_URL"] as string
  );
  const getTokenReq = new GetTokenRequest();
  getTokenReq.setCode(authCode);
  getTokenReq.setOrganizationId(organizationID);
  const res = await client.getToken(getTokenReq, {});
  return res.toObject();
};

const getStore = async (
  token: string,
  organizationID: string
): Promise<GetStoreResponse.AsObject> => {
  const client = new ApiV1Client(
    process.env["REACT_APP_API_BASE_URL"] as string
  );
  const res = await client.getStore(new EmptyRequest(), {
    authorization: `Bearer ${token}`,
    "x-organization-id": organizationID,
  });
  return res.toObject();
};

const makeTokenStorageKey = (organizationID: string) =>
  `token-${organizationID}`;

const decodeCustomerIdFromJwt = (token: string): string => {
  const [_header, payload, _signature] = token.split(".");
  // @ts-expect-error noUncheckedIndexedAccess
  const decodedPayload = JSON.parse(atob(payload)) as {
    "customer-id": string;
  };
  return decodedPayload["customer-id"];
};

export const AuthProvider = (props: Props) => {
  const [context, setContext] = useState<AuthContext>({
    state: "initial",
    data: null,
  });
  const query = new URLSearchParams(location.search);
  const authCode = query.get("auth_code");
  const organizationId = location.pathname.split("/")[1];

  useEffect(() => {
    (async () => {
      try {
        setContext({
          state: "loading",
          data: null,
        });

        let token;
        if (authCode) {
          // @ts-expect-error noUncheckedIndexedAccess
          const res = await getToken(authCode, organizationId);
          token = res.token;
          // @ts-expect-error noUncheckedIndexedAccess
          localStorage.setItem(makeTokenStorageKey(organizationId), token);
        } else {
          // @ts-expect-error noUncheckedIndexedAccess
          token = localStorage.getItem(makeTokenStorageKey(organizationId));
        }

        if (!token) {
          throw new Error("token is not found");
        }

        const customerId = decodeCustomerIdFromJwt(token);
        // @ts-expect-error noUncheckedIndexedAccess
        const res = await getStore(token, organizationId);
        const name = res.name;
        setContext({
          state: "success",
          data: {
            customerId,
            token,
            name,
            // @ts-expect-error noUncheckedIndexedAccess
            organizationID: organizationId,
            isEnableStoreVisitAppointment: res.isEnableStoreVisitAppoint,
            authCode: authCode || "",
          },
        });
      } catch (e) {
        logError(e);

        if (isGrpcError(e)) {
          const errorType = checkErrorType(e.code);
          setContext({
            state: "failed",
            data: {
              errorCode: errorType,
              error: new Error(JSON.stringify(e)),
            },
          });
        } else {
          setContext({
            state: "failed",
            data: {
              errorCode: "other",
              error: new Error(JSON.stringify(e)),
            },
          });
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    Sentry.setUser({
      authCode,
      organizationId,
    });
  }, [authCode, organizationId]);

  return (
    <AuthContext.Provider value={context}>
      {context.state === "loading" && <PageLoading fullscreen />}
      {context.state === "failed" && (
        <>
          <Header />
          <ErrorPage error={context.data.errorCode} />
        </>
      )}
      {context.state === "success" && props.children}
    </AuthContext.Provider>
  );
};
