import styles from "./StoreVisitAppointSuccess.module.scss";

export const StoreVisitAppointSuccess = () => {
  return (
    <div className={styles.container}>
      <h1 className={styles.heading}>来店予約をリクエストしました！</h1>
      <div className={styles.sentence}>
        <p>担当者から確定の連絡があるまで</p>
        <p>しばらくお待ちください</p>
      </div>
      <img
        alt="お問い合わせ完了！"
        className={styles.image}
        height={375}
        src="/images/StoreVisitAppointSuccess.png"
        width={375}
      />
    </div>
  );
};
